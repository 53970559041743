
import { Component, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { PageMode } from '@/utils/type'
import { apiMemberPriceDetail, apiMemberPriceEdit } from '@/api/marketing/member_price'

@Component({
    components: {
        MaterialSelect
    }
})
export default class memberPriceEdit extends Vue {
    mode: string = PageMode.EDIT // 当前页面【add: 添加用户等级 | edit: 编辑用户等级】

    // 折扣规则：1-根据会员等级设置，2-单独设置
    memberPriceType = {
        MEMBER_LEVEL_PRICE: 1, // 1-根据会员等级设置
        ALONE_SETUP: 2 // 2-单独设置
    }

    // 规格类型：1-单规格；2-多规格
    specType = {
        ALONE_SPECIFICATION: 1, // 单规格
        MUCH_SPECIFICATION: 2 // 多规格
    }

    goods_id = ''

    // 表单数据
    formData = {
        discount_rule: '',
        spec_type: '',
        is_discount: ''
    }

    $refs!: {
        formRef: any
    }

    // 折扣规则数据
    discountRulesData: any = []
	
	handleinput_money(value: any, row: any, item: any) {
		console.log( value,row,item )
	        value = value
	            .replace(/[^0-9.]/g, '') // 移除非数字和小数点字符
	            .replace(/(\..*)\./g, '$1'); // 只允许一个小数点
	
	        item.discount_price = value;
	
	        const originalPrice = parseFloat(item.sell_price);
	        if (originalPrice > 0) {
	            item.discount = ((parseFloat(value) / originalPrice) * 10).toFixed(2);
	        }
	
	        console.log('金额', value, '折扣', item.discount);
	    }
	
	    handleinput_discount(value: any, row: any, item: any, index: any) {
	        // value = value
	        //     .replace(/[^0-9.]/g, '') // 移除非数字和小数点字符
	        //     .replace(/(\..*)\./g, '$1'); // 只允许一个小数点
	
	//         // 处理小数点在开始位置的情况
	//         if (value.startsWith('.')) {
	//             value = '0' + value;
	//         }
	
	//         // 确保最多一位小数
	//         let parts = value.split('.');
	//         if (parts.length > 1 && parts[1].length > 1) {
	//             parts[1] = parts[1].substring(0, 1);
	//             value = parts.join('.');
	//         }
	
	        // 确保值在0到10之间
	        // if (value !== '') {
	        //     let num = parseFloat(value);
	        //     if (num < 0) num = 0;
	        //     if (num > 10) num = 10;
	        //     value = num.toString();
	        // }
	
	        item.discount = value;
	
	        const originalPrice = parseFloat(item.sell_price);
	        if (originalPrice > 0) {
	            item.discount_price = ((parseFloat(value) / 10) * originalPrice).toFixed(2);
	        }
			
	        console.log('折扣', value, '金额', item.discount_price, item, row );
			
			let items = row.goods_item
			for( let i in items ){
				let _row = items[i]
				_row.discount = value;
				const _originalPrice = parseFloat(_row.sell_price);
				if (_originalPrice > 0) {
				    _row.discount_price = ((parseFloat(value) / 10) * _originalPrice).toFixed(2);
				}
			}
			
	    }
	
    // 获取详情
    getMemberDiscountDetail() {
        apiMemberPriceDetail({
            goods_id: this.goods_id
        }).then((res: any) => {
            console.log(res, 'res')
            this.formData = res

            this.discountRulesData = res.level_goods_item
            console.log('discountRulesData', this.discountRulesData)
        })
    }

    // 编辑
    handleMemberDiscountEdit() {
        apiMemberPriceEdit({
            ...this.formData,
            id: this.goods_id
        }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 保存
    onSubmit() {
        this.$refs.formRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }

            switch (this.mode) {
                case PageMode.EDIT:
                    return this.handleMemberDiscountEdit()
            }
        })
    }

    created() {
        const query: any = this.$route.query
        if (query.id) {
            this.goods_id = query.id
        }

        this.getMemberDiscountDetail()
    }
}
